import React, { memo } from 'react'
import { IconProps } from '~svg'
import cls from 'classnames'

const PenIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={cls('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M10.7074 0.941578C11.2962 0.352807 12.2508 0.352807 12.8395 0.941578L15.0584 3.16047C15.6472 3.74924 15.6472 4.70382 15.0584 5.29259L5.85629 14.4947C5.64672 14.7043 5.38003 14.8475 5.08955 14.9063L2.30699 15.47C1.81165 15.5703 1.29898 15.4158 0.941603 15.0584C0.584227 14.701 0.429695 14.1884 0.530037 13.693L1.09371 10.9104C1.15255 10.62 1.2957 10.3533 1.50527 10.1437L10.7074 0.941578ZM2.33261 12.3882L2.42955 11.9097L4.09032 13.5704L3.61175 13.6674L2.00767 13.9923L2.33261 12.3882ZM5.43545 12.7834L3.21656 10.5646L9.6887 4.09241L11.9076 6.3113L5.43545 12.7834ZM12.9737 5.24524L13.9924 4.22653L12.9263 3.16047L12.8395 3.0737L11.7735 2.00764L10.7548 3.02635L12.9737 5.24524Z" 
        fill=""/>
    </svg>    
  )
}

export default memo(PenIcon)