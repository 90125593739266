import React, { memo } from 'react'
import { IconProps } from '~svg'
import cls from 'classnames'

const UserIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={cls('fill-current', className)}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00033 10.4344C5.47891 10.4344 5.06085 10.4712 4.70415 10.5257C3.23382 10.7503 2.00033 12.3113 2.00033 13.9127C2.00033 14.2969 1.70185 14.6084 1.33366 14.6084C0.965469 14.6084 0.666992 14.2969 0.666992 13.9127C0.666992 12.1088 1.75028 10.337 3.30639 9.54162C2.11279 8.6596 1.33366 7.20718 1.33366 5.56488C1.33366 2.87549 3.423 0.695312 6.00033 0.695312C8.57765 0.695312 10.667 2.87549 10.667 5.56488C10.667 7.20718 9.88786 8.6596 8.69426 9.54162C10.2504 10.337 11.3337 12.1088 11.3337 13.9127C11.3337 14.2969 11.0352 14.6084 10.667 14.6084C10.2988 14.6084 10.0003 14.2969 10.0003 13.9127C10.0003 12.3113 8.76683 10.7503 7.2965 10.5257C6.9398 10.4712 6.52174 10.4344 6.00033 10.4344ZM6.00033 9.04314C4.15938 9.04314 2.66699 7.48587 2.66699 5.56488C2.66699 3.64389 4.15938 2.08662 6.00033 2.08662C7.84127 2.08662 9.33366 3.64389 9.33366 5.56488C9.33366 7.48587 7.84127 9.04314 6.00033 9.04314Z"
        fill=""
      />
    </svg>
  )
}

export default memo(UserIcon)
