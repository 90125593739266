import React, { memo } from 'react'
import { IconProps } from '~svg'
import cls from 'classnames'

const FireIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg 
      className={cls('fill-current', className)}
      viewBox="0 0 12 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path 
        fill="" fillRule="evenodd" clipRule="evenodd" 
        d="M7.71159 0.734707C7.93635 0.97238 7.98115 1.32831 7.82229 1.61426C7.81151 1.63365 7.80078 1.65292 7.79011 1.67208C7.57191 2.06378 7.3788 2.41043 7.38534 2.8442C7.39144 3.24911 7.68302 3.98564 8.65032 4.80181C9.95957 5.90648 10.6068 7.26833 10.9267 8.33538C11.0871 8.87012 11.1676 9.33804 11.2082 9.67563C11.2285 9.84478 11.239 9.98227 11.2443 10.0803C11.247 10.1294 11.2484 10.1687 11.2492 10.1973C11.2495 10.2116 11.2498 10.2232 11.2499 10.232L11.25 10.2432L11.25 10.2471L11.25 10.2487C11.25 10.2487 11.25 10.25 10.5 10.25H11.25C11.25 13.1495 8.8995 15.5 6 15.5C3.10051 15.5 0.75 13.1495 0.75 10.25C0.75 8.8031 0.953114 7.54039 1.24149 6.60298C1.38482 6.13705 1.55652 5.72751 1.75072 5.40984C1.84769 5.25121 1.96177 5.09645 2.0962 4.96695C2.2253 4.84258 2.4161 4.70015 2.6687 4.64332C2.87483 4.59694 3.09097 4.63966 3.26396 4.76097C3.43695 4.88229 3.55074 5.07094 3.57736 5.28055C3.60141 5.46993 3.68009 5.74727 3.80532 6.04008C3.79907 5.54265 3.83583 5.01455 3.93751 4.48389C4.22078 3.0055 5.02433 1.45481 6.83959 0.575104C7.13396 0.432448 7.48684 0.497034 7.71159 0.734707ZM9.75 10.252C9.74892 12.3222 8.0704 14 6 14C3.92893 14 2.25 12.3211 2.25 10.25C2.25 8.96864 2.42627 7.88004 2.65668 7.10519C2.71576 7.21345 2.77857 7.31961 2.84476 7.42221C3.22167 8.0065 3.88933 8.75003 4.83334 8.75003C5.06965 8.75003 5.29214 8.63865 5.43375 8.44948C5.57536 8.2603 5.61953 8.01544 5.55295 7.78871C5.35828 7.12579 5.18649 5.93636 5.41071 4.76617C5.50637 4.26692 5.67097 3.78139 5.93199 3.34108C6.08386 4.17635 6.61591 5.04787 7.68302 5.94824C8.7071 6.81232 9.22658 7.88797 9.48994 8.76623C9.62125 9.20415 9.68658 9.58584 9.7189 9.85469C9.73503 9.98877 9.74281 10.0937 9.74656 10.1623C9.74844 10.1965 9.7493 10.2216 9.74969 10.2365L9.74999 10.2512L9.75 10.252Z" />
    </svg>
  )
}

export default memo(FireIcon)