import React, { memo } from 'react'
import { IconProps } from '~svg'
import cls from 'classnames'

const PDFIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={cls('fill-current', className)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect width="18" height="18" rx="3" fill="" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.63447 9.12685H3.9971C4.83367 9.12685 4.89902 8.88629 4.89902 8.5452C4.89902 8.15672 4.69795 7.96714 4.28649 7.96714H3.63447V9.12685ZM8.35157 10.7648H8.84992C9.40644 10.7648 9.97588 10.5997 9.97588 9.37317C9.97588 8.13663 9.37412 7.97793 8.84992 7.97793H8.35157V10.7648ZM3.34723 11.9927H2.53723C2.37854 11.9927 2.25 11.8635 2.25 11.7048V7.03723C2.25 6.87926 2.37854 6.75 2.53723 6.75H4.40785C5.52016 6.75 6.29785 7.48819 6.29785 8.54521C6.29785 9.61731 5.53668 10.3376 4.40426 10.3376H3.63447V11.7048C3.63447 11.8635 3.50593 11.9927 3.34723 11.9927ZM8.93537 11.9927H7.25433C7.09564 11.9927 6.9671 11.8635 6.9671 11.7048V7.03723C6.9671 6.87926 7.09564 6.75 7.25433 6.75H8.93537C10.5159 6.75 11.3862 7.67777 11.3862 9.36311C11.3862 11.0585 10.5159 11.9927 8.93537 11.9927ZM13.2669 11.9927H12.4561C12.2974 11.9927 12.1689 11.8635 12.1689 11.7048V7.03723C12.1689 6.87926 12.2974 6.75 12.4561 6.75H15.4628C15.6207 6.75 15.75 6.87926 15.75 7.03723V7.69715C15.75 7.85585 15.6207 7.98439 15.4628 7.98439H13.5541V8.83963H15.2732C15.4319 8.83963 15.5604 8.96817 15.5604 9.12686V9.77673C15.5604 9.93471 15.4319 10.064 15.2732 10.064H13.5541V11.7048C13.5541 11.8635 13.4256 11.9927 13.2669 11.9927Z" fill="white"/>
    </svg>
  )
}

export default memo(PDFIcon)
