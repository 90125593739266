import React, { memo } from 'react'
import { IconProps } from '~svg'
import cls from 'classnames'

const PhoneIcon: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg {...otherProps} className={cls('fill-current', className)} width='32' height='32' viewBox='0 0 32 32'
         fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.7479 13.1722C11.8445 12.0755 11.8445 10.2913 10.7479 9.19464L6.89119 5.33795C6.30125 4.74795 5.48343 4.44901 4.64824 4.51776C3.82899 4.5852 3.08799 5.00282 2.6153 5.66345C2.51718 5.80057 2.42243 5.9397 2.32961 6.07995L10.0848 13.8352L10.7479 13.1722Z'
        fill='#FF7143' />
      <path
        d='M26.6621 25.1088L22.8054 21.2521C21.7089 20.1556 19.9245 20.1555 18.8278 21.2521L18.1648 21.9152L25.9203 29.6707C26.0605 29.5779 26.1994 29.4827 26.3365 29.3847C26.9971 28.912 27.4147 28.171 27.4822 27.3517C27.5511 26.5164 27.2521 25.6989 26.6621 25.1088Z'
        fill='#FF7143' />
      <path
        d='M15.5133 23.4017C14.762 23.4017 14.0558 23.1091 13.5246 22.5779L9.42207 18.4754C8.89088 17.9442 8.59826 17.2379 8.59826 16.4867C8.59826 16.0599 8.69294 15.6478 8.87182 15.2739L1.37167 7.7737C0.316672 9.9889 -0.150204 12.4707 0.0424211 14.9618C0.301984 18.3187 1.74961 21.4721 4.11874 23.8412L8.15888 27.8812C10.5279 30.2503 13.6813 31.6979 17.0382 31.9576C17.405 31.9859 17.7715 32 18.1372 32C20.2543 32 22.3373 31.528 24.2263 30.6284L16.726 23.1281C16.3522 23.307 15.9401 23.4017 15.5133 23.4017Z'
        fill='#FF7143' />
      <path
        d='M18.875 0C18.3572 0 17.9375 0.419751 17.9375 0.937502C17.9375 1.45525 18.3572 1.875 18.875 1.875C25.0782 1.875 30.125 6.92176 30.125 13.125C30.125 13.6428 30.5447 14.0625 31.0625 14.0625C31.5802 14.0625 32 13.6428 32 13.125C32 5.88789 26.1121 0 18.875 0Z'
        fill='#FF7143' />
      <path
        d='M18.8749 13.1242C18.8749 13.1242 18.875 13.1245 18.875 13.125C18.875 13.6428 19.2947 14.0625 19.8125 14.0625C20.3302 14.0625 20.75 13.6428 20.75 13.125C20.75 12.0911 19.9088 11.25 18.875 11.25C18.3572 11.25 17.9375 11.6693 17.9375 12.1871C17.9375 12.7048 18.3572 13.1242 18.8749 13.1242Z'
        fill='#FF7143' />
      <path
        d='M18.875 9.37502C20.9427 9.37502 22.625 11.0573 22.625 13.125C22.625 13.6428 23.0447 14.0625 23.5625 14.0625C24.0802 14.0625 24.5 13.6428 24.5 13.125C24.5 10.0234 21.9766 7.50002 18.875 7.50002C18.3572 7.50002 17.9375 7.91977 17.9375 8.43752C17.9375 8.95527 18.3572 9.37502 18.875 9.37502Z'
        fill='#FF7143' />
      <path
        d='M18.875 3.75001C18.3572 3.75001 17.9375 4.16976 17.9375 4.68751C17.9375 5.20526 18.3572 5.62501 18.875 5.62501C23.0105 5.62501 26.375 8.98952 26.375 13.125C26.375 13.6428 26.7947 14.0625 27.3125 14.0625C27.8302 14.0625 28.25 13.6428 28.25 13.125C28.25 7.95564 24.0444 3.75001 18.875 3.75001Z'
        fill='#FF7143' />
    </svg>


  )
}

export default memo(PhoneIcon)